.text-cursor[data-v-744909e4] {
  cursor: pointer;
}
.block[data-v-744909e4] {
  margin: 20px 0;
  text-align: center;
}
.top_btn[data-v-744909e4] {
  margin: 0px 0 20px 0;
  border: 0;
  background-color: #fff;
}