.text-cursor {
  cursor: pointer;
}
.bindings.add-header {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.bindings .child_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.bindings .child_box .first_box {
  line-height: 41px;
  font-size: 16px;
  width: 150px;
}
.bindings .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.bindings .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.bindings .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.bindings .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.bindings .avatar-uploader {
  margin-right: 170px;
}
.bindings .box {
  margin-left: 0px;
}