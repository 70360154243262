.text-cursor {
  cursor: pointer;
}
.exParam {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.block {
  margin: 20px 0;
  text-align: center;
}
.top_btn {
  margin-bottom: 20px;
  border: 0;
  background-color: #fff;
}
.table_top {
  margin-top: 20px;
  margin-bottom: 20px;
}