.text-cursor[data-v-0864fad0] {
  cursor: pointer;
}
.el-radio[data-v-0864fad0] {
  margin-right: 30px !important;
}
.codeListsTop[data-v-0864fad0] {
  position: absolute;
  left: 20px;
  top: 60px;
  width: 100%;
  background: #ffffff;
  z-index: 999;
}