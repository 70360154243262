.page-login {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.page-login .page-login--layer {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.page-login .page-login--quick-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.text-cursor {
  cursor: pointer;
}
#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
}
.page-login {
  background-color: #f0f2f5;
  height: 100%;
  position: relative;
}
.page-login .title {
  font-size: 24px;
  color: #409eff;
  padding-bottom: 10px;
  font-weight: bold;
}
.page-login .page-login--layer {
  overflow: auto;
}
.page-login .page-login--layer-area {
  overflow: hidden;
}
.page-login .page-login--layer-time {
  font-size: 24em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.03);
  overflow: hidden;
}
.page-login .page-login--content {
  height: 100%;
  min-height: 500px;
}
.page-login .page-login--content-header {
  padding: 1em 0;
}
.page-login .page-login--content-header .page-login--content-header-motto {
  margin: 0px;
  padding: 0px;
  color: #606266;
  text-align: center;
  font-size: 12px;
}
.page-login .page-login--logo {
  width: 300px;
  margin-bottom: 2em;
  margin-top: -2em;
}
.page-login .page-login--form {
  width: 280px;
}
.page-login .page-login--form .el-card {
  margin-bottom: 15px;
}
.page-login .page-login--form .button-login {
  width: 100%;
  z-index: 999;
  position: relative;
}
.page-login .page-login--form .el-input-group__prepend {
  padding: 0px 14px;
}
.page-login .page-login--form .login-code {
  height: 38px;
  display: block;
  margin: 0px -20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.page-login .page-login--form .page-login--options {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  color: #409EFF;
  margin-bottom: 15px;
  font-weight: bold;
}
.page-login .page-login--form .page-login--quick {
  width: 100%;
}
.page-login .page-login--quick-user {
  padding: 10px 0px;
  border-radius: 4px;
}
.page-login .page-login--quick-user:hover {
  background-color: #f8f8f9;
}
.page-login .page-login--quick-user:hover i {
  color: #606266;
}
.page-login .page-login--quick-user:hover span {
  color: #606266;
}
.page-login .page-login--quick-user i {
  font-size: 36px;
  color: #909399;
}
.page-login .page-login--quick-user span {
  font-size: 12px;
  margin-top: 10px;
  color: #909399;
}
.page-login .page-login--content-footer {
  padding: 1em 0;
}
.page-login .page-login--content-footer .page-login--content-footer-locales {
  padding: 0px;
  margin: 0px;
  margin-bottom: 15px;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #606266;
}
.page-login .page-login--content-footer .page-login--content-footer-locales a {
  color: #606266;
  margin: 0 0.5em;
}
.page-login .page-login--content-footer .page-login--content-footer-locales a:hover {
  color: #303133;
}
.page-login .page-login--content-footer .page-login--content-footer-copyright {
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #606266;
}
.page-login .page-login--content-footer .page-login--content-footer-copyright a {
  color: #606266;
}
.page-login .page-login--content-footer .page-login--content-footer-options {
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
}
.page-login .page-login--content-footer .page-login--content-footer-options a {
  color: #606266;
  margin: 0 1em;
}
.page-login .circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
}
.page-login .circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #fff;
  -webkit-animation: animate 25s linear infinite;
          animation: animate 25s linear infinite;
  bottom: -200px;
}
@-webkit-keyframes animate {
0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
}
100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
}
}
@keyframes animate {
0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
}
100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
}
}
.page-login .circles li:nth-child(1) {
  left: 15%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.page-login .circles li:nth-child(2) {
  left: 5%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 12s;
          animation-duration: 12s;
}
.page-login .circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}
.page-login .circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}
.page-login .circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.page-login .circles li:nth-child(6) {
  left: 75%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}
.page-login .circles li:nth-child(7) {
  left: 35%;
  width: 200px;
  height: 200px;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}
.page-login .circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
  -webkit-animation-duration: 45s;
          animation-duration: 45s;
}
.page-login .circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 35s;
          animation-duration: 35s;
}
.page-login .circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 11s;
          animation-duration: 11s;
}
.show_code {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin-left: 20px;
  width: 130px;
}
.show_code .val_show {
  display: inline-block;
  border: 1px solid #3e4957;
  background: #283443;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  width: 95px;
  height: 40px;
  line-height: 47px;
  text-align: center;
}
.codeBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.codeBox .refresh {
  display: inline-block;
  padding-left: 5px;
  font-size: 20px;
}