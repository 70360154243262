.text-cursor {
  cursor: pointer;
}
.dataccont.dataAll-content {
  width: 100%;
  height: 100%;
}
.dataccont.dataAll-content .data-header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.dataccont.dataAll-content .data-body {
  border: 1px solid #dfe6ec;
  height: 650px;
  overflow: auto;
  color: #909399;
  overflow-x: auto;
}
.dataccont #table {
  width: auto;
  table-layout: fixed;
  border-top: 1px solid #dfe6ec;
  border-left: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
  border-spacing: 0;
  width: 100%;
  border: 0;
}
.dataccont #table thead {
  border-left: 1px solid #dfe6ec;
}
.dataccont #table thead #header {
  border-top: 1px solid #dfe6ec;
  width: 100%;
}
.dataccont #table thead #header th {
  line-height: 50px;
  border-right: 1px solid #dfe6ec;
  border-bottom: 1px solid #dfe6ec;
}
.dataccont #table #bodoy {
  border-left: 1px solid #dfe6ec;
}
.dataccont #table #bodoy tr td {
  padding: 10px 30px;
  border-bottom: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
  margin: 0;
  padding: 0;
  line-height: 30px;
  width: 150px;
}