.text-cursor[data-v-6c0c8f2c] {
  cursor: pointer;
}
.el-menu-demo[data-v-6c0c8f2c] {
  margin-bottom: 10px;
}
[data-v-6c0c8f2c] .el-select__tags-text {
  max-width: 40px;
}
[data-v-6c0c8f2c] .customizationRe .alarm-row {
  border-bottom: 0 !important;
  border-right: 0 !important;
}
[data-v-6c0c8f2c] .customizationRe .acquiesce-row,[data-v-6c0c8f2c] .customizationRe .el-table__row .el-table__cell {
  border-bottom: 0 !important;
  border-right: 0 !important;
}
[data-v-6c0c8f2c] .customizationRe .el-table__row .el-table__cell .cell {
  line-height: 20px;
  padding: 0 !important;
}
.tableDataText[data-v-6c0c8f2c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 14px;
  width: 100%;
  height: 100%;
  padding: 10px 18px;
}
.tableDataText .tableDataText-c[data-v-6c0c8f2c] {
  text-decoration: underline;
}
.tableDataText .tableDataText-b[data-v-6c0c8f2c] {
  color: #6f7882;
}
[data-v-6c0c8f2c] .el-select .el-input .el-input__inner {
  background: #f7f9fb !important;
  border: 1px solid #B2BFCF;
}
[data-v-6c0c8f2c] .el-date-editor,[data-v-6c0c8f2c] .el-range-input {
  background: #f0f0f0;
}
.commonalityJust[data-v-6c0c8f2c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 32px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.retentionAnalysisTitle[data-v-6c0c8f2c] {
  padding-top: 10px !important;
}
[data-v-6c0c8f2c] .el-row {
  margin-bottom: 0;
}
[data-v-6c0c8f2c] .el-row::before {
  content: none;
}
[data-v-6c0c8f2c] .el-row::after {
  content: none;
}
.Tipss[data-v-6c0c8f2c] {
  font-size: 16px;
}
.commonalitText[data-v-6c0c8f2c] {
  font-size: 14px;
  color: #8f9593;
  line-height: 20px;
  padding-top: 6px;
}
.commonalityBox[data-v-6c0c8f2c] {
  border-bottom: 1px solid #EEEEEE;
  padding: 10px 0;
  height: 32px;
}
.dataList[data-v-6c0c8f2c] {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 10px;
  position: relative;
}
.dataListItem[data-v-6c0c8f2c] {
  margin-right: 10px;
  width: 200px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.dataListItem .miniT[data-v-6c0c8f2c] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-right: 10px;
}