.text-cursor {
  cursor: pointer;
}
.block {
  margin: 20px 0;
  text-align: center;
}
.top_btn {
  margin: 0px 0 20px 0px;
  border: 0;
  background-color: #fff;
}