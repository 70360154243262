.text-cursor[data-v-22db3a8b] {
  cursor: pointer;
}
.dateClass[data-v-22db3a8b] {
  background: #5cb85c;
  border-radius: 2px;
  color: #ffffff;
  width: 80px;
  text-align: center;
}
[data-v-22db3a8b] .el-table {
  font-size: 12px;
}
[data-v-22db3a8b] .el-table .cell {
  padding: 8px 10px !important;
}
[data-v-22db3a8b] .el-table__header .el-table__cell {
  background: #fcfcfc !important;
  border-right: none;
}