.d2-source[data-v-114063e4] {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.text-cursor[data-v-114063e4] {
  cursor: pointer;
}
.d2-source[data-v-114063e4] {
  opacity: 0;
  position: fixed;
  z-index: 9999;
  right: -11.5px;
  bottom: 20px;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  border-radius: 4px;
  padding: 7px 15px;
  padding-right: 19px;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid #000;
  color: #FFF;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.d2-source.d2-source--active[data-v-114063e4] {
  opacity: 1;
}
.d2-source[data-v-114063e4]:hover {
  right: -4px;
  background-color: rgba(0, 0, 0, 0.9);
}