.text-cursor {
  cursor: pointer;
}
#perFormance {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.perFormance {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.perFormance .text {
  font-size: 18px;
  color: #fff;
}
.perFormance .topPart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.perFormance .topPart .moneyNum {
  font-size: 30px;
  font-weight: bold;
  color: #fef000;
}
.perFormance .topPart .criChartBox {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 400px;
  overflow: hidden;
  overflow-x: auto;
}
.perFormance .topPart .criChartBox::-webkit-scrollbar {
  width: 2px;
}
.perFormance .topPart .criChartBox .showTip {
  position: absolute;
  right: 1%;
  top: 0%;
  text-align: center;
  border-radius: 10px;
  width: 100px;
  padding: 10px;
  background: #fff;
  color: #3fc0fb;
}
.perFormance .topPart .criChartBox .items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}