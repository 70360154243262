
[data-v-78f5affd] .hide .el-upload--picture-card {
  display: none !important;
}
[data-v-78f5affd] .el-upload--picture-card {
  width: 120px;
  height: 120px;
  line-height: 120px;
}
[data-v-78f5affd] .el-upload {
  width: 120px;
  height: 120px;
  line-height: 120px;
}
[data-v-78f5affd] .el-upload-list--picture-card .el-upload-list__item {
  width: 120px;
  height: 120px;
}
[data-v-78f5affd] .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 120px;
  height: 120px;
  line-height: 120px;
}
[data-v-78f5affd] .avatar {
  width: 120px;
  height: 120px;
  line-height: 120px;
}
