
.t{
  padding: 20px;
  position: relative;
  min-height: 95.1%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.isSlide{
  overflow: hidden !important;
}
