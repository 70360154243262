@charset "UTF-8";
.text-cursor {
  cursor: pointer;
}
.fade-transverse-leave-active,
.fade-transverse-enter-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.fade-transverse-enter {
  opacity: 0;
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px);
}
.fade-transverse-leave-to {
  opacity: 0;
  -webkit-transform: translateX(30px);
          transform: translateX(30px);
}
.fade-scale-leave-active,
.fade-scale-enter-active {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.fade-scale-enter {
  opacity: 0;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.fade-scale-leave-to {
  opacity: 0;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.el-table__header .el-table__cell {
  background: #f8f8f9 !important;
  color: #515a6e !important;
  padding: 8px 18px !important;
}
.el-table--group,
.el-table--border {
  border: none !important;
}
.viewText {
  color: #0079FF;
  cursor: pointer;
}
.textpointer {
  cursor: pointer;
}
.el-table td.el-table__cell.is-leaf {
  color: #515a6e !important;
  text-align: center !important;
}
.el-table .el-table__cell {
  padding: 0 !important;
  white-space: nowrap;
  background: #FFFFFF;
}
.is-hidden {
  background: #FFFFFF !important;
}
.el-table .cell {
  padding: 12px 18px !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}
.el-col .el-select__tags-text {
  max-width: 80px;
}
.el-table__row .el-table__cell {
  border-right: 0 !important;
}
.el-table__row:nth-child(2n) .el-table__cell {
  background: rgba(245, 247, 250, 0.3);
}
.el-table__empty-block {
  min-height: 100% !important;
}
.el-table__empty-text {
  line-height: 50px !important;
}
.el-table__fixed::before {
  height: 0 !important;
}
.caret-wrapper {
  height: 0 !important;
}
.caret-wrapper .ascending {
  top: -13px !important;
}
.caret-wrapper .descending {
  bottom: -10px !important;
}
.el-form .el-row {
  margin-bottom: 20px;
}
#header {
  height: 100%;
}
#header th {
  background: #f8f8f9 !important;
  color: #515a6e !important;
  height: 100% !important;
  text-align: left;
  padding: 8px 18px;
  text-wrap: nowrap;
}
.lodingItem .el-date-editor.el-input,
.lodingItem .el-date-editor.el-input__inner {
  width: 100% !important;
}
.el-row .el-col {
  margin-bottom: 20px;
}
.el-row input {
  height: 32px;
  line-height: 32px;
}
.el-row .el-select input {
  height: 32px !important;
  line-height: 32px !important;
}
.el-row .el-date-editor.el-input,
.el-row .el-date-editor.el-input__inner {
  width: 100% !important;
  height: 32px;
  line-height: 32px;
}
.el-row .el-date-editor.el-input .el-range__icon,
.el-row .el-date-editor.el-input .el-range-separator,
.el-row .el-date-editor.el-input__inner .el-range__icon,
.el-row .el-date-editor.el-input__inner .el-range-separator {
  line-height: 24px;
}
.el-row .el-input__icon {
  line-height: 32px;
}
.el-row .el-range__close-icon {
  line-height: 26px !important;
}
.el-row .el-select {
  width: 100% !important;
  margin-bottom: 0 !important;
}
.el-row .el-button {
  font-size: 14px;
  border-radius: 3px;
  padding: 8px 15px;
}
.margin-top {
  margin-top: 20px;
}
.el-menu--popup-bottom-start {
  max-height: 80vh;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}
::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:vertical {
  height: 5px;
  background-color: rgba(125, 125, 125, 0.7);
}
::-webkit-scrollbar-thumb:horizontal {
  width: 5px;
  background-color: rgba(125, 125, 125, 0.7);
}
.levelDetails-body {
  border-top: 1px solid #dfe6ec;
  overflow: hidden;
  color: #515a6e;
  min-width: 100%;
  overflow-x: auto;
}
.font-bold {
  font-weight: bold;
}
.el-time-spinner .el-scrollbar {
  width: 100%;
}
#table {
  table-layout: fixed;
  border-spacing: 0;
  min-width: 100%;
  font-size: 14px;
}
#table thead {
  position: relative;
}
#table thead #newNum {
  border-top: 1px solid #dfe6ec;
  width: 100%;
}
#table thead #newNum th {
  border-bottom: 1px solid #dfe6ec;
  text-align: left;
  padding: 8px 18px;
}
#table thead #header {
  border-top: 1px solid #dfe6ec;
  min-width: 100%;
}
#table thead #header th {
  border-bottom: 1px solid #dfe6ec;
}
#table #bodoy tr {
  height: 100%;
}
#table #bodoy tr td {
  border-bottom: 1px solid #dfe6ec;
  text-align: left;
  padding: 12px 18px;
  width: 150px;
  text-wrap: nowrap;
}
#table #bodoy tr:nth-child(2n) {
  background: rgba(245, 247, 250, 0.3) !important;
}
#table #bodoy tr:hover {
  background: #F5F7FA;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.x-e {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.el-radio {
  margin-right: 0 !important;
}
.margin-B20 {
  margin-bottom: 20px;
}
.el-dialog__wrapper {
  position: absolute !important;
  background: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.el-dialog__wrapper .el-dialog {
  width: auto !important;
  z-index: 9999;
  margin: 0 !important;
}
.el-dialog__wrapper .el-dialog__body {
  max-width: 100%;
  padding: 10px 20px 0;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
.el-table__fixed-right::before {
  height: auto !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type=number] {
  -moz-appearance: textfield;
}
.popover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 680px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.popover .el-form-item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 0 !important;
}
.popover .el-select {
  width: 100%;
}
.popover .lodingItem-title {
  min-width: 110px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  color: #606266;
}
.popover .lodingItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 48%;
}
.popover .lodingItem .lodingItem-mintitle {
  min-width: 70px;
  font-size: 14px;
  color: #606266;
}
.popover .lodingItem .lodingItem-operationFrame {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.popover .lodingItem .lodingItem-operationFrame .operationFrame-entity {
  width: 100%;
}

/*在Chrome下移除input[number]的上下箭头*/
.no-number::-webkit-outer-spin-button,
.no-number::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}
.no-number input[type=number]::-webkit-outer-spin-button,
.no-number input[type=number]::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}

/*在firefox下移除input[number]的上下箭头*/
.no-number {
  -moz-appearance: textfield;
}
.no-number input[type=number] {
  -moz-appearance: textfield;
}
.text-ellipsis-1 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.el-tooltip__popper {
  max-width: 400px;
  overflow: hidden;
}
.text-ellipsis-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
}
.text-ellipsis-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
}
.controlsBtn {
  color: #2d8cf0;
  background: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}
.controlsBtn-vertical {
  margin: 0 8px;
  display: inline-block;
  height: 14px;
  width: 1px;
  vertical-align: middle;
  position: relative;
  background: #e8eaec;
}
.operationBut {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 99;
}
.operationButTow {
  bottom: -52px;
}
.caret-wrapper {
  width: 18px !important;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}
.more_item_clear .el-select .el-select__tags .el-tag .el-tag__close {
  display: none !important;
}
.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.grid.grid-square {
  overflow: hidden;
}
.grid.grid-square .cu-tag {
  position: absolute;
  right: 0;
  top: 0;
  border-bottom-left-radius: 6px;
  padding: 6px 12px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.grid.grid-square > div > text[class*=cuIcon-] {
  font-size: 52px;
  position: absolute;
  color: #8799a3;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.grid.grid-square > div {
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}
.grid.grid-square > div.bg-img image {
  width: 100%;
  height: 100%;
  position: absolute;
}
.grid.col-1.grid-square > div {
  padding-bottom: 100%;
  height: 0;
  margin-right: 0;
}
.grid.col-2.grid-square > div {
  padding-bottom: calc((100% - 20px)/2);
  height: 0;
  width: calc((100% - 20px)/2);
}
.grid.col-3.grid-square > div {
  padding-bottom: calc((100% - 40px)/3);
  height: 0;
  width: calc((100% - 40px)/3);
}
.grid.col-4.grid-square > div {
  padding-bottom: calc((100% - 60px)/4);
  height: 0;
  width: calc((100% - 60px)/4);
}
.grid.col-5.grid-square > div {
  padding-bottom: calc((100% - 80px)/5);
  height: 0;
  width: calc((100% - 80px)/5);
}
.grid.col-2.grid-square > div:nth-child(2n),
.grid.col-3.grid-square > div:nth-child(3n),
.grid.col-4.grid-square > div:nth-child(4n),
.grid.col-5.grid-square > div:nth-child(5n) {
  margin-right: 0;
}
.grid.col-1 > div {
  width: 100%;
}
.grid.col-2 > div {
  width: 50%;
}
.grid.col-3 > div {
  width: 33.33%;
}
.grid.col-4 > div {
  width: 25%;
}
.grid.col-5 > div {
  width: 20%;
}

/* ==================
          布局
 ==================== */
/*  -- flex弹性布局 -- */
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.basis-xs {
  -ms-flex-preferred-size: 20%;
      flex-basis: 20%;
}
.basis-sm {
  -ms-flex-preferred-size: 40%;
      flex-basis: 40%;
}
.basis-df {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
}
.basis-lg {
  -ms-flex-preferred-size: 60%;
      flex-basis: 60%;
}
.basis-xl {
  -ms-flex-preferred-size: 80%;
      flex-basis: 80%;
}
.flex-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.flex-2 {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
}
.flex-3 {
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
}
.flex-direction {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.flex-nowrep {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
.align-start {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.align-end {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.align-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.align-stretch {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.self-start {
  -ms-flex-item-align: start;
      align-self: flex-start;
}
.self-center {
  -ms-flex-item-align: flex-center;
      align-self: flex-center;
}
.self-end {
  -ms-flex-item-align: end;
      align-self: flex-end;
}
.self-stretch {
  -ms-flex-item-align: stretch;
      align-self: stretch;
}
.align-stretch {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.justify-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.justify-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

/* ==================
          flex布局(colorui里面也有相关基础样式)
 ==================== */
/* x水平居中*/
.x-f {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/*x两端且水平居中*/
.x-bc {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/*x水平靠右居中*/
.x-je {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

/*x平分且水平居中*/
.x-ac {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/*x水平靠上对齐*/
.x-start {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

/*x水平靠下对齐*/
.x-ae {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

/*上下左右居中*/
.x-c {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/*y竖直靠左*/
.y-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

/*y竖直靠右*/
.y-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

/*y竖直居中*/
.y-f {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/* y竖直两端*/
.y-b {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

/*y竖直两端居中*/
.y-bc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

/*y竖直上下左右居中*/
.y-c {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.marginB {
  margin-bottom: 20px !important;
}
.marginL {
  margin-left: 20px !important;
}
.cursorView {
  cursor: pointer;
}
.ondayClass {
  background: #D3E3FD !important;
}
.maskModul {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  left: 0px;
  right: 0px;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
}