.text-cursor[data-v-cf72ff96] {
  cursor: pointer;
}
.notfound1B[data-v-cf72ff96] {
  width: 100%;
  height: 100%;
}
.cards[data-v-cf72ff96] {
  border: 1px solid #ebeef5;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  height: 640px;
  border-radius: 10px;
}
.chatHistory[data-v-cf72ff96] {
  background: #f8f8f8;
  padding: 20px;
  height: 100%;
  overflow: auto;
}
.chatHistory .chatMonitorItem[data-v-cf72ff96] {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
}
.chatHistory .chatMonitorItem .chatMonitorItem-title .chatMonitorItem-name[data-v-cf72ff96] {
  font-size: 16px;
  margin-right: 10px;
}
.chatHistory .chatMonitorItem .chatMonitorItem-title .middle[data-v-cf72ff96] {
  margin: 0 20px;
  font-size: 16px;
}
.chatHistory .chatMonitorItem .chatMonitorItem-title .chatMonitorItem-userID[data-v-cf72ff96] {
  font-size: 14px;
  margin-right: 10px;
}
.chatHistory .chatMonitorItem .chatMonitorItem-title .chatMonitorItem-time[data-v-cf72ff96] {
  font-size: 12px;
}
.chatHistory .chatMonitorItem .chatMonitorItem-content[data-v-cf72ff96] {
  background: #ffffff;
  border-radius: 10px;
  padding: 8px 14px;
  font-size: 14px;
  margin-top: 16px;
}