.text-cursor {
  cursor: pointer;
}
.commdataing.dataAll-content {
  width: 100%;
  height: 100%;
}
.commdataing.dataAll-content .dataCompare-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.commdataing.dataAll-content .box-posie {
  width: 100%;
  position: relative;
}
.commdataing.dataAll-content .payAccountRetain-body {
  border: 1px solid #dfe6ec;
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
  color: #909399;
  table-layout: fixed;
}
.commdataing.dataAll-content .table-headers {
  background-color: #f8f8f9 !important;
  color: #515a6e !important;
  min-width: 100%;
}
.commdataing #title th {
  border-right: 1px solid #dfe6ec;
  border-bottom: 1px solid #dfe6ec;
  line-height: 40px;
  min-width: 206x;
}
.commdataing #payAccountRetain-table {
  border-top: 1px solid #dfe6ec;
  border-left: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
  border-spacing: 0;
  border: 0;
  table-layout: fixed;
  min-width: 100%;
}
.commdataing #payAccountRetain-table thead {
  border-left: 1px solid #dfe6ec;
}
.commdataing #payAccountRetain-table thead #header {
  border-top: 1px solid #dfe6ec;
}
.commdataing #payAccountRetain-table thead #header th {
  border-right: 1px solid #dfe6ec;
  border-bottom: 1px solid #dfe6ec;
  padding: 0;
  text-align: center;
  min-width: 100px;
}
.commdataing #payAccountRetain-table #bodoy {
  border-left: 1px solid #dfe6ec;
}
.commdataing #payAccountRetain-table #bodoy tr {
  min-width: 100px;
}
.commdataing #payAccountRetain-table #bodoy tr td {
  min-width: 100px;
  padding: 10px 30px;
  border-bottom: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 30px;
  width: 150px;
}
.commdataing #payAccountRetain-table .left-title {
  background-color: #fff;
  position: relative;
  z-index: 10;
}