
.BTN {
  margin-bottom: 20px;
}
.block {
  margin: 20px 0;
  text-align: center;
}
.top_btn {
  background-color: #fff;
}
.statusTag1 {
  color: red;
}
.statusTag2 {
  color: green;
}
