.text-cursor {
  cursor: pointer;
}
.statiStical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px;
}
.statiStical .text {
  color: #c3cbde;
}
.statiSticalChaet {
  height: 100%;
}