.text-cursor[data-v-54a13c8a] {
  cursor: pointer;
}
.first_box[data-v-54a13c8a] {
  margin-right: 10px;
  width: 90px;
}
.x-f[data-v-54a13c8a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.popover[data-v-54a13c8a] {
  width: 1010px;
}
.leftItem[data-v-54a13c8a] {
  margin-right: 10px;
}
[data-v-54a13c8a].el-date-editor.el-input {
  width: 100% !important;
}