.text-cursor {
  cursor: pointer;
}
.coreChannelBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.coreChannelBox .coreChannel-item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.overview-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  height: 34px;
}
.overview-search .el-button {
  padding: 6px 14px;
}
.overview-search .icon-shuaxin {
  font-size: 12px;
  margin-right: 6px;
}
.overview-search .refresh {
  font-weight: bold;
}
.searchbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 280px;
}
.searchbs .search-t {
  background: #f1f2f6;
  color: #adb0b7;
  min-width: 50px;
  max-width: 50px;
  height: 100%;
  font-size: 14px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-right: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 32px;
  border-radius: 4px 0 0 4px;
}
.searchbs .el-input__inner {
  height: 34px;
  line-height: 34px;
  border-radius: 0 4px 4px 0;
}
.searchbs .el-range__icon {
  line-height: 28px;
}
.searchbs .el-range-separator {
  line-height: 28px;
}
.searchbs .el-select__caret {
  line-height: 28px;
}
.searchbs .el-select__tags-text {
  max-width: 80px;
}
.overview-title {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0 10px;
}
.overview-item {
  position: relative;
  padding: 20px 0;
}
.overview-item .overview-item-l {
  font-size: 15px;
  font-weight: bold;
  color: #656565;
}
.overview-item .overview-item-v {
  font-size: 24px;
  font-weight: bold;
  color: #2096ed;
  margin: 4px 0;
}
.overview-item .overview-item-p {
  font-size: 12px;
  color: #acadb1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.overview-item .segmentation {
  width: 1px;
  height: 100%;
  background: #efefef;
  position: absolute;
  right: 0;
  top: 0;
}
.overview-item .iconfont {
  font-size: 9px;
  margin-left: 2px;
}
.overview-item .gocommen {
  border-radius: 10px;
  padding: 2px 8px;
  margin-left: 4px;
}
.overview-item .goDown {
  background: #f0fdf4;
  color: #77bc59;
}
.overview-item .goDown .gocommen-percentage {
  font-weight: 500;
  font-size: 14px;
}
.overview-item .goUp {
  background: #fef2f2;
  color: #e46b6c;
}
.models {
  margin-bottom: 30px;
}