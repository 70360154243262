.text-cursor[data-v-6edc30c4] {
  cursor: pointer;
}
.titles[data-v-6edc30c4] {
  font-size: 20px;
}
.box_header[data-v-6edc30c4] {
  background-color: #f5f5f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: bold;
  padding: 10px;
  font-size: 14px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.box_header[data-v-6edc30c4] .el-button.is-round {
  padding: 6px 13px;
}
.popover[data-v-6edc30c4] {
  width: 800px;
}
.popover-item[data-v-6edc30c4] {
  width: 49%;
  border: 1px solid #666666;
  /* border-radius: 10px; */
}