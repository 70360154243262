.text-cursor[data-v-4189a258] {
  cursor: pointer;
}
.x-f[data-v-4189a258] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 20px;
}
.Dynamic .lodingItem[data-v-4189a258] {
  margin-bottom: 0 !important;
}
.child_boxss[data-v-4189a258] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
}
.child_boxss .el-select[data-v-4189a258] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.first_box[data-v-4189a258] {
  margin-right: 20px;
  width: 100px;
}
.el-date-editor[data-v-4189a258] {
  width: 100% !important;
}
[data-v-4189a258] .el-input__inner {
  width: 100% !important;
}