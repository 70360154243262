.text-cursor[data-v-0d5b564e] {
  cursor: pointer;
}
.popover[data-v-0d5b564e] {
  width: 1200px !important;
}
[data-v-0d5b564e] .el-textarea__inner {
  height: 200px;
}
#content[data-v-0d5b564e] {
  width: 1160px;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  padding: 10px 20px;
}