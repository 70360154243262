.text-cursor[data-v-72421be4] {
  cursor: pointer;
}
.codeListsTop[data-v-72421be4] {
  position: absolute;
  left: 20px;
  top: 60px;
  width: 100%;
  background: #ffffff;
  z-index: 999;
}
.test[data-v-72421be4] {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}