.text-cursor {
  cursor: pointer;
}
.tables-posi {
  position: relative;
}
.bott {
  margin-top: 40px;
}
.block {
  margin: 20px 0;
  text-align: center;
}