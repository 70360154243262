.text-cursor[data-v-dd6c92c6] {
  cursor: pointer;
}
[data-v-dd6c92c6] .el-table {
  font-size: 12px;
}
[data-v-dd6c92c6] .el-table .cell {
  padding: 8px 10px !important;
}
[data-v-dd6c92c6] .el-table__header .el-table__cell {
  background: #fcfcfc !important;
  border-right: none;
}
.tipss[data-v-dd6c92c6] {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}
[data-v-dd6c92c6] .tipsClass .el-input__suffix {
  right: 20px;
}
[data-v-dd6c92c6] .tipsClass .el-input__inner {
  padding-right: 40px;
}