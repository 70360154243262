.text-cursor[data-v-056155ae] {
  cursor: pointer;
}
.orderNum[data-v-056155ae] {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
}
.orderNum #orderNumC[data-v-056155ae] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.orderNum .text[data-v-056155ae] {
  font-size: 18px;
  color: #fff;
  margin-bottom: 20px;
}