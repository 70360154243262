.text-cursor {
  cursor: pointer;
}
.userlisttt.dataAll-content {
  width: 100%;
  height: 100%;
}
.userlisttt.dataAll-content .table_box {
  margin-top: 70rpx;
}
.userlisttt.dataAll-content .table_box .top_btn {
  margin: 70px 0 10px 30px;
  border: 0;
  background-color: #fff;
}
.userlisttt.dataAll-content .accountRetain-header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.userlisttt.dataAll-content .block {
  margin: 20px 0;
  text-align: center;
}
.userlisttt.dataAll-content .levelDetails-body {
  border: 1px solid #dfe6ec;
  width: 90%;
  max-height: 650px;
  margin-top: 50px;
  width: 100%;
  overflow: auto;
  color: #909399;
}
.userlisttt.dataAll-content .clickUserID {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  color: skyBlue;
}