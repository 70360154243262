.text-cursor {
  cursor: pointer;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}
.item_boxings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.item_boxings .text {
  font-size: 14px;
  width: 120px;
  line-height: 40px;
  color: #000000;
}