
.block[data-v-ed28e5c0] {
  margin: 20px 0;
  text-align: center;
}
.statusTag1[data-v-ed28e5c0] {
  color: red;
}
.statusTag2[data-v-ed28e5c0] {
  color: green;
}
