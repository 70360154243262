.text-cursor[data-v-9fc2bff0] {
  cursor: pointer;
}
.popover[data-v-9fc2bff0] {
  width: 900px;
}
.itemBox[data-v-9fc2bff0] {
  width: 100%;
  padding-left: 50%;
}
[data-v-9fc2bff0] .el-input__inner {
  background: none !important;
  color: #606266 !important;
}
[data-v-9fc2bff0] .el-textarea__inner {
  background: none !important;
  color: #606266 !important;
}