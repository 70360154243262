@charset "UTF-8";
.text-cursor[data-v-1aa636ac] {
  cursor: pointer;
}
.other[data-v-1aa636ac] {
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
}
.other .dv-scroll-board .rows .row-item[data-v-1aa636ac] {
  height: 60px !important;
  line-height: 60px !important;
}
.therList[data-v-1aa636ac] {
  width: 100%;
  height: 100%;
  padding-right: 20px;
}
.text-ellipsis-1[data-v-1aa636ac] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

/*最外层透明*/
[data-v-1aa636ac] .el-table,[data-v-1aa636ac] .el-table__expanded-cell,[data-v-1aa636ac] .el-table__header {
  background-color: transparent !important;
  width: 100% !important;
}

/* 表格内背景颜色 */
[data-v-1aa636ac] .el-table th,[data-v-1aa636ac] .el-table tr,[data-v-1aa636ac] .el-table td {
  background-color: transparent !important;
}

/*去除底边框*/
/*去除底部灰条.el-table::before*/