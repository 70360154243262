.text-cursor[data-v-5b864713] {
  cursor: pointer;
}
[data-v-5b864713] .el-input__inner {
  background: none !important;
  color: #606266 !important;
}
[data-v-5b864713] .el-textarea__inner {
  background: none !important;
  color: #606266 !important;
}