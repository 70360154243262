.text-cursor {
  cursor: pointer;
}
.online {
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.online .text {
  margin-top: 10px;
  font-size: 18px;
  color: #fff;
}
.online .onlineChart {
  height: 100%;
}