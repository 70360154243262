.text-cursor[data-v-8ff8e52a] {
  cursor: pointer;
}
.el-date-editor.el-input[data-v-8ff8e52a],
.el-date-editor.el-input__inner[data-v-8ff8e52a] {
  width: 100% !important;
}
.item_boxings[data-v-8ff8e52a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.item_boxings .text[data-v-8ff8e52a] {
  font-size: 14px;
  width: 120px;
  line-height: 40px;
  color: #000000;
}
.popover[data-v-8ff8e52a] {
  width: 1000px !important;
}