.text-cursor {
  cursor: pointer;
}
.adverssing.dataAll-content {
  width: 100%;
  height: 100%;
}
.adverssing.dataAll-content .data-header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.adverssing .block {
  margin: 20px 0;
  text-align: center;
}