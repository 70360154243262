.text-cursor {
  cursor: pointer;
}
.data-header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.deviceDuration_body {
  overflow: auto;
  color: #909399;
  font-size: 14px;
}
#deviceDuration_table {
  width: auto;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  border: 0;
}
#deviceDuration_table thead #header {
  width: 100%;
}
#deviceDuration_table thead #header th {
  border-bottom: 1px solid #dfe6ec;
}
#deviceDuration_table #bodoy tr td {
  padding: 12px 18px;
  border-bottom: 1px solid #dfe6ec;
  width: 150px;
}