.text-cursor[data-v-1605817a] {
  cursor: pointer;
}
.tipsd[data-v-1605817a] {
  font-size: 12px;
  margin-top: 10px;
  color: #999999;
}
.TableLeft[data-v-1605817a] {
  padding: 8px 10px;
  border-radius: 10px;
  border: 1px solid #DCDFE6;
}
.TableLeft .TableItem[data-v-1605817a] {
  padding: 0 8px;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
}
.TableLeft .TableItemClose[data-v-1605817a] {
  color: #409EFF;
}
.SQLinquireItem[data-v-1605817a] {
  padding: 20px;
}
.fields .fieldsItem[data-v-1605817a] {
  margin-right: 10px;
  margin-bottom: 10px;
}
.dataList[data-v-1605817a] {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 10px;
}
.dataListItem[data-v-1605817a] {
  margin-right: 10px;
  width: 290px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.dataListItem .miniT[data-v-1605817a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-right: 10px;
}