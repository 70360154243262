.text-cursor[data-v-f069e63c] {
  cursor: pointer;
}
.wellcome[data-v-f069e63c] {
  font-size: 22em;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  height: 80vh;
}
.headerClass[data-v-f069e63c] {
  text-align: center;
  border-left: 1px solid #dfe6ec;
}
.el-row[data-v-f069e63c] {
  height: 100%;
}
.el-card[data-v-f069e63c] {
  border-radius: 10px;
}
.selecticon[data-v-f069e63c] {
  position: absolute;
  width: 50px;
  height: 50px;
  right: 0;
  bottom: 0;
}
[data-v-f069e63c] .el-checkbox {
  margin: 0 30px 20px 0 !important;
}
.itemList[data-v-f069e63c] {
  margin-top: 30px;
  font-size: 16px;
}
.itemList .itemList-i[data-v-f069e63c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.itemList .itemList-i .itemList-i-i[data-v-f069e63c] {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.itemList .itemList-i .itemList-i-t[data-v-f069e63c] {
  font-size: 16px;
  font-weight: 700;
  margin: 8px 0;
  color: #515a6e;
}
.itemList .itemList-i .itemList-i-d[data-v-f069e63c] {
  font-size: 14px;
  color: #808695;
}
.index-t[data-v-f069e63c] {
  position: relative;
  font-size: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
}
.index-t .index-t-l[data-v-f069e63c] {
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  background: #4f74ff;
  height: 100%;
}
[data-v-f069e63c] .el-input--suffix input {
  height: 32px !important;
}