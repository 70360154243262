.text-cursor {
  cursor: pointer;
}

.el-pagination {
  text-align: center !important;
  padding: 20px 0;
}

.el-menu-sticky {
  position: sticky !important;
  top: 0;
  background: #EBF1F6 !important;
  z-index: 999;
}

.el-picker-panel.no-atTheMoment .el-button--text.el-picker-panel__link-btn {
  display: none;
}