.text-cursor {
  cursor: pointer;
}
.block {
  margin: 20px 0;
  text-align: center;
}
.top_btn {
  margin-bottom: 20px;
  border: 0;
  background-color: #fff;
}
.statusTag1 {
  color: red;
}
.statusTag2 {
  color: green;
}
.table_top {
  margin-top: 20px;
}