.text-cursor[data-v-118cecac] {
  cursor: pointer;
}
.data-header[data-v-118cecac] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.onlineDatadv-body[data-v-118cecac] {
  border: 0;
  overflow: auto;
  color: #909399;
}