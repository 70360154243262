.text-cursor {
  cursor: pointer;
}
.gameChart {
  padding: 10px;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#ranking-board {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.text {
  font-size: 18px;
  color: #fff;
  padding-bottom: 10px;
}