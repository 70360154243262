.text-cursor[data-v-37714a80] {
  cursor: pointer;
}
.addtag[data-v-37714a80] {
  padding: 2px 4px;
  border: 1px solid #999999;
  color: cornflowerblue;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}
.first_box[data-v-37714a80] {
  margin-right: 10px;
  width: 90px;
}
.x-f[data-v-37714a80] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.popover[data-v-37714a80] {
  width: 1010px;
}
.leftItem[data-v-37714a80] {
  margin-right: 10px;
}
[data-v-37714a80].el-date-editor.el-input {
  width: 100% !important;
}