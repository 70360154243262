.text-cursor[data-v-b8e17e28] {
  cursor: pointer;
}
.page[data-v-b8e17e28] {
  background: #303133;
  background-blend-mode: multiply, multiply;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.page .page_title[data-v-b8e17e28] {
  font-size: 20px;
  color: #FFF;
}