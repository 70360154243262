.text-cursor[data-v-2d4a862b] {
  cursor: pointer;
}
.popover[data-v-2d4a862b] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.popover .y-f[data-v-2d4a862b] {
  margin-bottom: 20px;
}
.popover .y-f .y-f-title[data-v-2d4a862b] {
  font-size: 16px;
  margin-bottom: 6px;
}