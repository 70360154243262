.text-cursor {
  cursor: pointer;
}
.modlus {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: #000;
  height: 100%;
  z-index: 999;
}