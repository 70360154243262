.text-cursor {
  cursor: pointer;
}
.advvsorigindd.dataAll-content {
  width: 100%;
  height: 100%;
}
.advvsorigindd.dataAll-content .data-header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.advvsorigindd.dataAll-content .flex-spce {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.advvsorigindd.dataAll-content .data-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.advvsorigindd.dataAll-content .titles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.advvsorigindd.dataAll-content .proportion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.advvsorigindd.dataAll-content .widthing {
  width: 150px;
  text-align: center;
  line-height: 100px;
  border: 1px solid #dfe6ec;
}
.advvsorigindd.dataAll-content .none-bor {
  border-top: none;
  border-right: none;
}
.advvsorigindd .button_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}