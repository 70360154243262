.text-cursor[data-v-57603c80] {
  cursor: pointer;
}
[data-v-57603c80] .el-table .el-table__cell {
  border-right: 0 !important;
}
.tipss[data-v-57603c80] {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}
[data-v-57603c80] .tipsClass .el-input__suffix {
  right: 20px;
}
[data-v-57603c80] .tipsClass .el-input__inner {
  padding-right: 40px;
}
.tipssSlot[data-v-57603c80] {
  position: absolute;
  right: 280px;
  z-index: 10;
  top: 15px;
}