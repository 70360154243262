.text-cursor[data-v-212d534f] {
  cursor: pointer;
}
table[data-v-212d534f] {
  width: 100%;
  border-collapse: collapse;
}
th[data-v-212d534f],
td[data-v-212d534f] {
  border: 1px solid #999;
  text-align: center;
  width: 150px;
  height: 30px;
}