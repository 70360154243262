.text-cursor {
  cursor: pointer;
}
.swallowOperation-header {
  width: 100%;
}
.key-sty {
  margin-left: 38px;
}
.swallowOperation-header .el-date-picker {
  width: 150px;
}
.statu-width {
  width: 145px;
}
.dataopera-sty {
  margin-left: 109px;
}
.swallowOperation_body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #dfe6ec;
  max-height: 450px;
  margin-top: 20px;
  overflow: auto;
  color: #909399;
  font-size: 16px;
  font-weight: bold;
}
.swallowOperation_body .persom {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.swallowOperation_body .persom .title {
  border-right: 1px solid #dfe6ec;
  border-bottom: 1px solid #dfe6ec;
  background: #3c5496;
  color: #ffffff;
}
.swallowOperation_body .persom > div:nth-child(2) {
  border-right: 1px solid #dfe6ec;
}
.swallowOperation_body .times {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.swallowOperation_body .times .ttim {
  border-bottom: 1px solid #dfe6ec;
  background: #3c5496;
  color: #ffffff;
}
.swallowOperation_body > div {
  text-align: center;
  line-height: 40px;
}