.text-cursor[data-v-15ce4756] {
  cursor: pointer;
}
[data-v-15ce4756] .el-textarea.is-disabled .el-textarea__inner {
  background: none !important;
  color: #606266;
}
.recoverb[data-v-15ce4756] {
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #999999;
  border-radius: 10px;
  position: relative;
}
[data-v-15ce4756] .el-tabs__item {
  font-size: 16px !important;
}
[data-v-15ce4756] .el-tabs__item {
  text-align: center;
  padding: 0 20px !important;
  border-radius: 10px 10px 0 0;
}
[data-v-15ce4756] .el-tabs__active-bar {
  display: none;
}
[data-v-15ce4756] .el-tabs__nav .is-active {
  background: #cccccc;
  color: #ffffff;
}
.disponibles[data-v-15ce4756] {
  color: #909399;
  font-size: 16px;
  padding: 20px;
  border-bottom: 1px solid #ebeef5;
}
.backList[data-v-15ce4756] {
  max-width: 100%;
  font-size: 16px;
}
.backList-item[data-v-15ce4756] {
  max-width: 100%;
  margin-bottom: 20px;
}
.backList-item .backList-item-box[data-v-15ce4756] {
  padding: 10px;
  border-radius: 4px;
}
.backList-item .backList-item-top[data-v-15ce4756] {
  margin-bottom: 10px;
}
.backList-item .backList-item-topright .controlsBtn[data-v-15ce4756] {
  margin-left: 10px;
}
.backList-item .backList-item-topright .marginR[data-v-15ce4756] {
  font-size: 14px;
  color: #808080;
  margin-right: 10px;
}
.backList-item:nth-child(2n-1) .backList-item-box[data-v-15ce4756] {
  background: #efefef;
}