.text-cursor {
  cursor: pointer;
}
.msgShareing.dataAll-content {
  width: 100%;
}
.msgShareing.dataAll-content .levelDetails-body {
  margin-top: 30px;
  border: none;
}
.msgShareing.dataAll-content .block {
  margin: 20px 0;
  text-align: center;
}
.msgShareing.dataAll-content .top_btn {
  margin: 10px 0 0px;
  border: 0;
  background-color: #fff;
}
.msgShareing .button_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.mask {
  position: absolute;
  z-index: 5;
  width: calc(100% - 10px);
  height: calc(100vw - 87px);
  left: 0px;
  right: 0px;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
}