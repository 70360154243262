.text-cursor[data-v-b9491266] {
  cursor: pointer;
}
div[data-v-b9491266] {
  font-family: "Heavy";
}
.corner[data-v-b9491266] {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: -1;
}
.corner div[data-v-b9491266] {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 20%;
}
.corner .corner-tl[data-v-b9491266] {
  left: 0;
  top: 0;
  border-top: 2px solid #02a6b5;
  border-left: 2px solid #02a6b5;
}
.corner .corner-tr[data-v-b9491266] {
  right: 0;
  top: 0;
  border-top: 2px solid #02a6b5;
  border-right: 2px solid #02a6b5;
}
.corner .corner-bl[data-v-b9491266] {
  left: 0;
  bottom: 0;
  border-bottom: 2px solid #02a6b5;
  border-left: 2px solid #02a6b5;
}
.corner .corner-br[data-v-b9491266] {
  right: 0;
  bottom: 0;
  border-bottom: 2px solid #02a6b5;
  border-right: 2px solid #02a6b5;
}
.wraptit[data-v-b9491266] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0 0 10px 0;
  margin-bottom: 10px;
}
.wraptitItem[data-v-b9491266] {
  border: none !important;
  color: #fff !important;
}
.wraptit div[data-v-b9491266]:nth-child(1) {
  width: 25%;
}
.wraptit div[data-v-b9491266]:nth-child(2) {
  width: 25%;
}
.wraptit div[data-v-b9491266]:nth-child(3) {
  width: 25%;
}
.wraptit div[data-v-b9491266]:nth-child(4) {
  width: 25%;
}
.seamless-warp[data-v-b9491266] {
  height: 190px;
  overflow: hidden;
}
.rankingClass[data-v-b9491266] {
  height: 216px;
  overflow: hidden;
}
.rateItme[data-v-b9491266] {
  width: 16%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rateItme .rateTitle[data-v-b9491266] {
  display: block;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}
.echartClass[data-v-b9491266] {
  height: 100%;
}
.boxall[data-v-b9491266] {
  max-width: 100%;
  padding: 15px;
  background: rgba(255, 255, 255, 0.04) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeAAAAFNAQMAAADimC7mAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAGUExURQAAAGWE4vqQbh8AAAACdFJOUwAX9UBI/wAAAORJREFUeNrt2rEJwkAAheGnWFg6QXAQC8dK6QbZwEnsUzqEpVU67cROESEQ9Dz5rjAgpvr4r5CXZJt5skp2WY78WCTrzHI/lb5c6UGF6pMvD6e+azbt0+MynPtu//DlsWsO7fXll6hUhQpVaaqJdxgqVaFCVZSq5B2GSlWoUE091d5hqlIVKlRv7rCxD1SqQoWqNNXEOwyVqlCh+rn/9L91h6FSFSpUthWqUhUq2wrbClWhQmVbgUpVqFDZVqBSFSpUthWqUhUq2wrbClWhQmVbgUpVqFDZVqBSFSpUthWqQvXXVDeeUfX257CIIwAAAABJRU5ErkJggg==);
  position: relative;
  margin-bottom: 15px;
  z-index: 10;
}
.boxall .alltitle[data-v-b9491266] {
  font-size: 18px;
  color: #fff;
  position: relative;
  padding-left: 12px;
  margin-bottom: 10px;
}
.boxall .alltitle[data-v-b9491266]::before {
  width: 5px;
  height: 18px;
  top: 2px;
  position: absolute;
  content: "";
  background: #36bcff;
  border-radius: 20px;
  left: 0;
}
.dataLeft[data-v-b9491266] {
  width: 24%;
  padding: 7.5px;
}
.dataCenter[data-v-b9491266] {
  width: 52%;
  padding: 7.5px;
  position: relative;
}
.dataRight[data-v-b9491266] {
  width: 24%;
  padding: 7.5px;
}
[data-v-b9491266] .t {
  padding: 0;
}
.largeScreenDataBox[data-v-b9491266] {
  background: #06164a;
  width: 100%;
}
.table1[data-v-b9491266] {
  margin-top: 30px;
}
.table1 .table1Item[data-v-b9491266] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 16px;
  color: white;
  font-weight: normal;
  text-align: center;
}
.table1 .table1Item .tr[data-v-b9491266] {
  border: none;
  padding: 14px 0 0;
}
.table1Item .col[data-v-b9491266] {
  width: 42%;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 0 0 14px 0;
  border-bottom: 1px solid white;
}
.table1Item span[data-v-b9491266] {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  display: block;
  background: #878787;
  color: #fff;
  line-height: 24px;
  text-align: center;
}
.table1Item .spans0[data-v-b9491266] {
  background: #ed405d !important;
}
.table1Item .spans1[data-v-b9491266] {
  background: #f78c44;
}
.table1Item .spans2[data-v-b9491266] {
  background: #49bcf7;
}
.table1Item .spans3[data-v-b9491266] {
  background: #929671;
}
.toptop[data-v-b9491266] {
  width: 10% !important;
  text-align: center;
  margin-right: 6%;
}
.map[data-v-b9491266] {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.map .map1[data-v-b9491266],
.map .map2[data-v-b9491266],
.map .map3[data-v-b9491266] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 6.475rem;
  height: 6.475rem;
  background-image: url(../img/map.5335662a.png);
  background-size: 100% 100%;
  opacity: 0.3;
}
.map .map2[data-v-b9491266] {
  width: 8.0375rem;
  height: 8.0375rem;
  background-image: url(../img/lbx.96c657ad.png);
  opacity: 0.6;
  -webkit-animation: rotate-data-v-b9491266 15s linear infinite;
          animation: rotate-data-v-b9491266 15s linear infinite;
  z-index: 2;
}
.map .map3[data-v-b9491266] {
  width: 7.075rem;
  height: 7.075rem;
  background-image: url(../img/jt.8713f05d.png);
  -webkit-animation: rotate1-data-v-b9491266 10s linear infinite;
          animation: rotate1-data-v-b9491266 10s linear infinite;
}
@-webkit-keyframes rotate-data-v-b9491266 {
from {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
}
to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
}
}
@keyframes rotate-data-v-b9491266 {
from {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
}
to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
}
}
@-webkit-keyframes rotate1-data-v-b9491266 {
from {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
}
to {
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
            transform: translate(-50%, -50%) rotate(-360deg);
}
}
@keyframes rotate1-data-v-b9491266 {
from {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
}
to {
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
            transform: translate(-50%, -50%) rotate(-360deg);
}
}