.text-cursor[data-v-8c27a254] {
  cursor: pointer;
}
.data-header[data-v-8c27a254] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.bootDevice-body[data-v-8c27a254] {
  border: 1px solid #dfe6ec;
  margin-top: 50px;
  width: 100%;
  overflow: auto;
  color: #909399;
  max-height: 700px;
}
.block[data-v-8c27a254] {
  margin: 20px 0;
  text-align: center;
}
.top_btn[data-v-8c27a254] {
  margin-bottom: 20px;
  border: 0;
  background-color: #fff;
}