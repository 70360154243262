.text-cursor[data-v-600bd5a0] {
  cursor: pointer;
}
.addtag[data-v-600bd5a0] {
  padding: 2px 4px;
  border: 1px solid #999999;
  color: cornflowerblue;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}
.popover[data-v-600bd5a0] {
  width: 1010px;
}