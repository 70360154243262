.text-cursor[data-v-2758ab52] {
  cursor: pointer;
}
.inpBox[data-v-2758ab52] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.inpBox .inpItem[data-v-2758ab52] {
  width: 24%;
}
.inpBox .inpItem .inpItemTitle[data-v-2758ab52] {
  width: 100px;
  font-size: 16px;
  margin-top: 10px;
}
.inpBox .inpClass[data-v-2758ab52] {
  width: 100%;
  margin-bottom: 20px;
}