.text-cursor[data-v-7e4ad11f] {
  cursor: pointer;
}
.tipss[data-v-7e4ad11f] {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
[data-v-7e4ad11f] .tipsClass .el-input__suffix {
  right: 20px;
}
[data-v-7e4ad11f] .tipsClass .el-input__inner {
  padding-right: 40px;
}