.text-cursor[data-v-6eb5b554] {
  cursor: pointer;
}
.popover[data-v-6eb5b554] {
  width: 800px;
}
.leftItem[data-v-6eb5b554] {
  margin-right: 10px;
}
[data-v-6eb5b554] .el-input__inner {
  background: none !important;
  color: #606266 !important;
}
[data-v-6eb5b554] .el-textarea__inner {
  background: none !important;
  color: #606266 !important;
}