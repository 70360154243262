.text-cursor[data-v-426bf7c5] {
  cursor: pointer;
}
.seachBox[data-v-426bf7c5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 20px;
}
.seachBox .seachLeft[data-v-426bf7c5] {
  margin-right: 20px;
}
.seachBox .seachRight .dataPicker[data-v-426bf7c5] {
  width: 280px;
  margin-right: 20px;
}
[data-v-426bf7c5] .el-checkbox--medium {
  margin-right: 0 !important;
}
.tableBox[data-v-426bf7c5] {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: auto;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-collapse: collapse;
}
.tableBox table[data-v-426bf7c5] {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border-color: #000000;
  color: #000000;
  font-size: 14px;
}
.tableBox table tr[data-v-426bf7c5] {
  width: 100%;
}
.tableBox table tr td[data-v-426bf7c5] {
  min-width: 100px;
  padding: 14px;
  text-align: center;
  display: table-cell;
  vertical-align: inherit;
}