.text-cursor[data-v-f8222b9e] {
  cursor: pointer;
}
.el-date-editor.el-input[data-v-f8222b9e],
.el-date-editor.el-input__inner[data-v-f8222b9e] {
  width: 100% !important;
}
.item_boxings[data-v-f8222b9e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.item_boxings .text[data-v-f8222b9e] {
  font-size: 14px;
  width: 120px;
  line-height: 40px;
  color: #000000;
}
[data-v-f8222b9e] .popover {
  width: 800px !important;
}