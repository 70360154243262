.text-cursor[data-v-79e65390] {
  cursor: pointer;
}
[data-v-79e65390] .el-table::before {
  height: 0 !important;
}
.titles[data-v-79e65390] {
  font-size: 20px;
}
.content[data-v-79e65390] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.content .table_box[data-v-79e65390] {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.content .item_box[data-v-79e65390] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 30%;
  height: 750px;
  margin: 0 20px;
  border: 1px solid grey;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.content .item_box .active[data-v-79e65390] {
  background-color: #f5f5f5;
}
.content .item_box .box_header[data-v-79e65390] {
  background-color: #f5f5f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: bold;
  padding: 10px;
  font-size: 14px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.content .item_box .box_header[data-v-79e65390] .el-button.is-round {
  padding: 6px 13px;
}
.content .item_box .box_td[data-v-79e65390] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-weight: bold;
  padding: 15px 20px;
  font-size: 14px;
  border-top: 1px solid #f5f5f5;
  border-bottom: 2px solid #f5f5f5;
}
.content .item_box .box_th[data-v-79e65390] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 15px 20px;
  font-size: 14px;
  border-bottom: 2px solid #f5f5f5;
}
.content .item_box .box_th .box_icon[data-v-79e65390] {
  width: 13%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.content .item_box .box_th[data-v-79e65390]:last-child {
  border: 0;
}
.content .item_box[data-v-79e65390]:nth-of-type(2) {
  width: 30%;
}
.content .item_box:nth-of-type(2) .box_header[data-v-79e65390] {
  padding: 10px 15px 10px 20px;
}
.content .item_box:nth-of-type(2) .box_td[data-v-79e65390] {
  padding: 15px 30px;
}
.content .item_box:nth-of-type(2) .box_th[data-v-79e65390] {
  padding: 15px 30px;
}
.content .item_box:nth-of-type(2) .box_th .box_icon[data-v-79e65390] {
  width: 10%;
}