.text-cursor[data-v-2363e2ad] {
  cursor: pointer;
}
[data-v-2363e2ad] .el-tag--light {
  max-width: 120px;
}
.multi-el-cascader[data-v-2363e2ad] {
  width: 100%;
}
[data-v-2363e2ad] .el-tag {
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
}
[data-v-2363e2ad].multi-el-cascader__label span {
  color: #909399 !important;
}
.popover[data-v-2363e2ad] {
  width: 760px;
}