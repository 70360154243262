.text-cursor[data-v-59854856] {
  cursor: pointer;
}
.paramsClass[data-v-59854856] {
  width: 100%;
}
[data-v-59854856] .el-input__inner {
  background: none !important;
  color: #606266 !important;
  cursor: pointer !important;
}