.text-cursor[data-v-a2a497c6] {
  cursor: pointer;
}
[data-v-a2a497c6] .custom-box__body {
  padding: 0 20px;
}
.addtag[data-v-a2a497c6] {
  padding: 2px 10px;
  border: 1px solid #999999;
  color: cornflowerblue;
  border-radius: 4px;
  margin-right: 10px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
}
.popover[data-v-a2a497c6] {
  width: 1000px;
}