.text-cursor[data-v-0373387a] {
  cursor: pointer;
}
[data-v-0373387a] .el-select__tags-text {
  max-width: 40px;
}
[data-v-0373387a] .alarm-row {
  background: #8de9c9 !important;
  border-bottom: 0 !important;
  border-right: 0 !important;
}
[data-v-0373387a] .acquiesce-row,
.el-table__row .el-table__cell[data-v-0373387a] {
  background: #a3edd3 !important;
  border-bottom: 0 !important;
  border-right: 0 !important;
}
.tableDataText[data-v-0373387a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 14px;
  width: 100%;
  height: 100%;
  padding: 10px 18px;
}
.tableDataText .tableDataText-c[data-v-0373387a] {
  text-decoration: underline;
}
.tableDataText .tableDataText-b[data-v-0373387a] {
  color: #6f7882;
}
[data-v-0373387a] .el-table__row .el-table__cell .cell {
  line-height: 20px;
  padding: 0 !important;
}
[data-v-0373387a] .el-select .el-input .el-input__inner {
  background: #f7f9fb !important;
  border: 1px solid #B2BFCF;
}
[data-v-0373387a] .el-date-editor,[data-v-0373387a] .el-range-input {
  background: #f0f0f0;
}
.commonalityJust[data-v-0373387a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 32px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.retentionAnalysisTitle[data-v-0373387a] {
  padding-top: 10px !important;
}
[data-v-0373387a] .el-row {
  margin-bottom: 0;
}
[data-v-0373387a] .el-row::before {
  content: none;
}
[data-v-0373387a] .el-row::after {
  content: none;
}
.Tipss[data-v-0373387a] {
  font-size: 16px;
}
.commonalitText[data-v-0373387a] {
  font-size: 14px;
  color: #8f9593;
  line-height: 20px;
  padding-top: 6px;
}
.commonalityBox[data-v-0373387a] {
  border-bottom: 1px solid #EEEEEE;
  padding: 10px 0;
  height: 32px;
}