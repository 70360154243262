@charset "UTF-8";
.text-cursor {
  cursor: pointer;
}
.main-article {
  padding: 20px;
  margin: 0 auto;
  display: block;
  width: 740px;
  background: #fff;
}
.article__heading {
  position: relative;
  padding: 0 0 20px;
  overflow: hidden;
}
.article__heading__title {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
  color: #333;
  overflow: hidden;
}
.node-article-content {
  margin: 20px 0 0;
  font-size: 16px;
  color: #333;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-bottom: 30px;
  font-family: medium-content-serif-font, Georgia, Cambria, "Times New Roman", Times, serif;
  /* 解决 IE6-7 图片缩放锯齿问题 */
}
.node-article-content:before {
  display: table;
  content: "";
  clear: both;
}
.node-article-content:after {
  display: table;
  content: "";
  clear: both;
}
.node-article-content > :last-child {
  margin-bottom: 0;
}
.node-article-content b,
.node-article-content strong {
  font-weight: inherit;
  font-weight: bolder;
}
.node-article-content img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.node-article-content p {
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  line-height: 1.58;
  letter-spacing: -0.003em;
}
.node-article-content ul {
  margin-bottom: 30px;
}
.node-article-content li {
  --x-height-multiplier: 0.375;
  --baseline-multiplier: 0.17;
  letter-spacing: 0.01rem;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  line-height: 1.58;
  letter-spacing: -0.003em;
  margin-left: 30px;
  margin-bottom: 14px;
}
.node-article-content a {
  text-decoration: none;
  background-repeat: repeat-x;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(100%, rgba(0, 0, 0, 0.84)), color-stop(0, rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.84) 100%, rgba(0, 0, 0, 0) 0);
  background-size: 1px 1px;
  background-position: 0 calc(1em + 1px);
  padding: 0 6px;
}
.node-article-content code {
  background: rgba(0, 0, 0, 0.05);
  padding: 3px 4px;
  margin: 0 2px;
  font-size: 16px;
  display: inline-block;
}
.node-article-content img {
  border: 0;
}
.node-article-content img {
  -ms-interpolation-mode: bicubic;
}
.node-article-content blockquote {
  --x-height-multiplier: 0.375;
  --baseline-multiplier: 0.17;
  font-family: medium-content-serif-font, Georgia, Cambria, "Times New Roman", Times, serif;
  letter-spacing: 0.01rem;
  font-weight: 400;
  font-style: italic;
  font-size: 21px;
  line-height: 1.58;
  letter-spacing: -0.003em;
  border-left: 3px solid rgba(0, 0, 0, 0.84);
  padding-left: 20px;
  margin-left: -23px;
  padding-bottom: 2px;
}
.node-article-content a {
  text-decoration: none;
}
.node-article-content h2,
.node-article-content h3,
.node-article-content h4 {
  font-size: 34px;
  line-height: 1.15;
  letter-spacing: -0.015em;
  margin: 53px 0 0;
}
.node-article-content h4 {
  font-size: 26px;
}