.text-cursor[data-v-fde46e3a] {
  cursor: pointer;
}
.x-f[data-v-fde46e3a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.titles[data-v-fde46e3a] {
  font-size: 20px;
  font-weight: bold;
}
.table_box[data-v-fde46e3a] {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.item_box[data-v-fde46e3a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 24%;
  border: 1px solid grey;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  height: 650px;
  margin: 0 0.6% 0 0;
  cursor: pointer;
}
.item_box .box_header[data-v-fde46e3a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: bold;
  padding: 10px;
  font-size: 14px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.item_box .box_header[data-v-fde46e3a] .el-button.is-round {
  padding: 6px 13px;
}
.item_box .box_td[data-v-fde46e3a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-weight: bold;
  padding: 15px 20px;
  font-size: 14px;
  border-top: 1px solid #f5f5f5;
  border-bottom: 2px solid #f5f5f5;
}
.item_box .box_th[data-v-fde46e3a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 15px 20px;
  font-size: 14px;
  border-bottom: 2px solid #f5f5f5;
}
.item_box .box_th .box_icon[data-v-fde46e3a] {
  width: 13%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.item_box .box_th[data-v-fde46e3a]:last-child {
  border: 0;
}