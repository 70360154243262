.text-cursor {
  cursor: pointer;
}
.codeListsTop {
  position: absolute;
  left: 20px;
  top: 60px;
  width: 100%;
  background: #ffffff;
  z-index: 999;
}
.modlus {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: #000;
  min-height: 100%;
  z-index: 999;
}
.viewText {
  color: #0079ff;
  cursor: pointer;
}