.text-cursor[data-v-0e99d1a4] {
  cursor: pointer;
}
.popover[data-v-0e99d1a4] {
  width: 500px;
}
.popover .lodingItem[data-v-0e99d1a4] {
  width: 100%;
}
.popover .lr[data-v-0e99d1a4] {
  margin-left: 10px;
}