.text-cursor[data-v-4a073bc6] {
  cursor: pointer;
}
.dataAll-content[data-v-4a073bc6] {
  width: 100%;
  height: 100%;
}
.dataAll-content .table_box .top_btn[data-v-4a073bc6] {
  margin: 40px 0 10px 30px;
  border: 0;
  background-color: #fff;
}
.accountRetain-header[data-v-4a073bc6] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 100px;
}
.accountRetain-header .btnn[data-v-4a073bc6] {
  margin-left: 50px;
}
.accountRetain-header .block[data-v-4a073bc6] {
  margin: 20px 0;
  text-align: center;
}
.accountRetain-header .levelDetails-body[data-v-4a073bc6] {
  border: 1px solid #dfe6ec;
  width: 90%;
  max-height: 650px;
  margin-top: 50px;
  width: 100%;
  overflow: auto;
  color: #909399;
}