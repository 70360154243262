.text-cursor[data-v-49a2f570] {
  cursor: pointer;
}
.serverOption[data-v-49a2f570] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.addtag[data-v-49a2f570] {
  padding: 2px 4px;
  border: 1px solid #999999;
  color: cornflowerblue;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}
.first_box[data-v-49a2f570] {
  margin-right: 10px;
  width: 90px;
}
.x-f[data-v-49a2f570] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.popover[data-v-49a2f570] {
  width: 900px;
}
.leftItem[data-v-49a2f570] {
  margin-right: 10px;
}
[data-v-49a2f570].el-date-editor.el-input {
  width: 100% !important;
}