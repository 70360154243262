.text-cursor {
  cursor: pointer;
}
.accrrgg.dataAll-content {
  width: 100%;
  height: 100%;
}
.accrrgg.dataAll-content .data-header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.accrrgg.dataAll-content .accRangeLoss_body {
  border: 1px solid #dfe6ec;
  max-height: 500px;
  overflow: auto;
  color: #909399;
}
.accrrgg #newNum th {
  border-bottom: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
}
.accrrgg #accRangeLoss_table {
  width: auto;
  table-layout: fixed;
  border-top: 1px solid #dfe6ec;
  border-left: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
  border-spacing: 0;
  width: 100%;
  border: 0;
}
.accrrgg #accRangeLoss_table thead {
  border-left: 1px solid #dfe6ec;
}
.accrrgg #accRangeLoss_table thead #header {
  border-top: 1px solid #dfe6ec;
  width: 100%;
}
.accrrgg #accRangeLoss_table thead #header th {
  border-right: 1px solid #dfe6ec;
  border-bottom: 1px solid #dfe6ec;
}
.accrrgg #accRangeLoss_table #bodoy {
  border-left: 1px solid #dfe6ec;
}
.accrrgg #accRangeLoss_table #bodoy tr td {
  padding: 10px 30px;
  border-bottom: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 30px;
  width: 150px;
}