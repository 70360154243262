.text-cursor[data-v-76eacdbf] {
  cursor: pointer;
}
.advooorring.dataAll-content[data-v-76eacdbf] {
  width: 100%;
  height: 100%;
}
.advooorring.dataAll-content .swallowPrimitive-header .el-date-picker[data-v-76eacdbf] {
  width: 150px;
}
.advooorring.dataAll-content .swallowPrimitive-header[data-v-76eacdbf] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.advooorring.dataAll-content .swallowOperation_body[data-v-76eacdbf] {
  border: 1px solid #dfe6ec;
  max-height: 450px;
  margin-top: 50px;
  width: 100%;
  overflow: auto;
  color: #909399;
}
.advooorring .statess-sty[data-v-76eacdbf] {
  margin-left: 116px;
}
.advooorring .statessing-sty[data-v-76eacdbf] {
  margin-left: 117px;
}
.advooorring .action-marg[data-v-76eacdbf] {
  margin-left: 35px;
  margin-right: 25px;
}
.advooorring .actionpri-sty[data-v-76eacdbf] {
  margin-left: 30px;
}
.advooorring .actionpri-btn[data-v-76eacdbf] {
  margin-left: 30px;
}
.advooorring .swallowOperation_body > .title[data-v-76eacdbf] {
  border-bottom: 1px solid #dfe6ec;
  background: #f0efef;
  font-weight: 700;
}
.advooorring .swallowOperation_body > div[data-v-76eacdbf] {
  text-align: center;
  line-height: 40px;
}
.advooorring .block[data-v-76eacdbf] {
  margin: 20px 0;
  text-align: center;
}
.advooorring .button_box[data-v-76eacdbf] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}