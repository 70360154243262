.text-cursor[data-v-32a765ce] {
  cursor: pointer;
}
[data-v-32a765ce] .el-radio {
  margin-right: 20px !important;
}
.contentValitem[data-v-32a765ce] {
  margin: 0 0 0 20px;
}
.main_contentClass[data-v-32a765ce] {
  margin-bottom: 10px;
  width: 100%;
}
.lodingItems[data-v-32a765ce] {
  margin-bottom: 40px !important;
}
.lodingItemt[data-v-32a765ce] {
  margin-bottom: 10px !important;
}
.lodingItemTips[data-v-32a765ce] {
  position: absolute;
  left: 0;
  top: calc(100% + 20px);
  line-height: 12px;
  font-size: 12px;
}
.addtag[data-v-32a765ce] {
  padding: 2px 4px;
  border: 1px solid #999999;
  color: cornflowerblue;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}
.first_box[data-v-32a765ce] {
  margin-right: 20px;
  width: 80px;
}
.popover[data-v-32a765ce] {
  width: 990px;
}