.text-cursor {
  cursor: pointer;
}
.incommChannle.dataAll-content {
  width: 100%;
  height: 100%;
}
.incommChannle.dataAll-content .dataSummary-header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.incommChannle.dataAll-content .type-padds {
  margin-left: 12px;
}
.incommChannle.dataAll-content .levelDetails-body {
  margin-top: 30px;
  border: none;
}
.incommChannle.dataAll-content .block {
  margin: 20px 0;
  text-align: center;
}
.incommChannle.dataAll-content .top_btn {
  margin: 10px 0 0px;
  border: 0;
  background-color: #fff;
}
.incommChannle .button_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.incommChannle .mask {
  position: absolute;
  z-index: 5;
  width: calc(100% - 10px);
  height: calc(100vw - 87px);
  left: 0px;
  right: 0px;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
}