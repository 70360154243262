.text-cursor[data-v-382eeda3] {
  cursor: pointer;
}
.fileList[data-v-382eeda3] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.fileList .fileList-item[data-v-382eeda3] {
  width: 100%;
  border: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 4px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin: 2px 0;
}
.fileList .fileList-item .fileList-itemRight[data-v-382eeda3] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
}
.fileList .fileList-item .fileList-itemRight .el-icon-close[data-v-382eeda3] {
  font-size: 18px;
  margin-left: 20px;
}
.fileList .fileList-item[data-v-382eeda3]:hover {
  color: #409eff;
  background: #f5f7fa;
}
.inputClass[data-v-382eeda3] {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.recoveryUpContent[data-v-382eeda3] {
  width: 400px;
  height: 100px;
}