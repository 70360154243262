@charset "UTF-8";
.text-cursor[data-v-4d8ae16d] {
  cursor: pointer;
}
.privayyy[data-v-4d8ae16d] {
  padding: 0px !important;
}
.privayyy.warrp[data-v-4d8ae16d] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.privayyy.warrp .header[data-v-4d8ae16d] {
  width: 100%;
  height: 220px;
  background: #61AD01;
  background-image: url(https://ossweb-img.qq.com/images/game/portal2012/colorBelt1.jpg);
}
.privayyy.warrp .content[data-v-4d8ae16d] {
  background: #F1F1F1;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.privayyy.warrp .priva-cont[data-v-4d8ae16d] {
  position: relative;
  z-index: 2;
  top: -180px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 1000px;
  background: #fff;
  padding: 30px;
  padding-bottom: 0px;
  -webkit-box-shadow: 5px 5px 2px #eee;
          box-shadow: 5px 5px 2px #eee;
}
.privayyy.warrp .priva-cont .icon-img[data-v-4d8ae16d] {
  padding-top: 0px;
  padding-bottom: 0px;
}
.privayyy.warrp .priva-cont .title[data-v-4d8ae16d] {
  text-align: center;
  color: #444444;
  font-family: Verdana, 微软雅黑;
}
.privayyy.warrp .priva-cont .title h2[data-v-4d8ae16d] {
  display: block;
  font-size: 30px;
}
.privayyy.warrp .priva-cont .line[data-v-4d8ae16d] {
  width: 100%;
  height: 1px;
  background: #D1D1D1;
  margin-top: 15px;
  margin-bottom: 15px;
}
.privayyy.warrp .priva-cont .clause[data-v-4d8ae16d] {
  font-size: 14px;
  color: #444;
  font-family: Verdana, 微软雅黑;
  line-height: 26px;
}
.privayyy.warrp .priva-cont .box[data-v-4d8ae16d] {
  width: 100%;
}
.privayyy.warrp .priva-cont .box .sdk-tab[data-v-4d8ae16d] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.privayyy.warrp .priva-cont .box .sdk-tab .selete-menu[data-v-4d8ae16d] {
  width: 25%;
}
.privayyy.warrp .priva-cont .box .detail-container[data-v-4d8ae16d] {
  width: 75%;
}
.privayyy.warrp .priva-cont .box .detail-container .game-sdk[data-v-4d8ae16d] {
  display: block;
  padding: 0px 20px;
}
.privayyy.warrp .priva-cont .box .detail-container .game-sdk .sdk-title[data-v-4d8ae16d] {
  display: block;
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 18px;
}
.privayyy.warrp .priva-cont .box .detail-container .game-sdk .sdk-detail[data-v-4d8ae16d] {
  color: #444;
  font-size: 14px;
  line-height: 26px;
  padding-top: 15px;
}
.privayyy.warrp .priva-cont .box .detail-container .game-sdk .sdk-detail .item-block[data-v-4d8ae16d] {
  display: block;
  margin-bottom: -20px;
  font-weight: 600;
}
.privayyy.warrp .priva-cont .box .detail-container .game-sdk .sdk-detail .item-detail[data-v-4d8ae16d] {
  display: block;
  margin-bottom: 20px;
}
.privayyy.warrp .footer[data-v-4d8ae16d] {
  margin-top: -140px;
  width: 100%;
}
.privayyy.warrp .footer .footer-item[data-v-4d8ae16d] {
  cursor: pointer;
  width: 920px;
  margin: 0px auto;
  font-size: 14px;
  color: #444;
  font-family: Verdana, 微软雅黑;
}