.text-cursor {
  cursor: pointer;
}
.getwayPaggggConf.dataAll-content {
  width: 100%;
  height: 100%;
}
.getwayPaggggConf.dataAll-content .dataSummary-header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.getwayPaggggConf.dataAll-content .levelDetails-body {
  border: none;
}
.getwayPaggggConf.dataAll-content .block {
  margin: 20px 0;
  text-align: center;
}
.getwayPaggggConf.dataAll-content .top_btn {
  border: 0;
  background-color: #fff;
}
.getwayPaggggConf.dataAll-content #table {
  width: auto;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}
.getwayPaggggConf.dataAll-content #table thead {
  border-left: 1px solid #dfe6ec;
}
.getwayPaggggConf.dataAll-content #table thead #header {
  border-top: 1px solid #dfe6ec;
  width: 100%;
}
.getwayPaggggConf.dataAll-content #table thead #header th {
  border-right: 1px solid #dfe6ec;
  border-bottom: 1px solid #dfe6ec;
}
.getwayPaggggConf.dataAll-content #table #bodoy {
  border-left: 1px solid #dfe6ec;
}
.getwayPaggggConf.dataAll-content #table #bodoy tr td {
  padding: 10px 30px;
  border-bottom: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 30px;
  width: 150px;
}
.getwayPaggggConf .button_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}