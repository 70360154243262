
.block[data-v-0551f865] {
  margin: 20px 0;
  text-align: center;
}
.top_btn[data-v-0551f865] {
  margin-bottom: 20px;
  border: 0;
  background-color: #fff;
}
