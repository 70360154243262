.text-cursor[data-v-9b5f5b46] {
  cursor: pointer;
}
.child_boxss[data-v-9b5f5b46] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
}
.child_boxss .el-select[data-v-9b5f5b46] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.first_box[data-v-9b5f5b46] {
  margin-right: 20px;
  width: 60px;
}