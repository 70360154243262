.text-cursor[data-v-5cf155d0] {
  cursor: pointer;
}
.dateClasst[data-v-5cf155d0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dateClasst .screenIconB[data-v-5cf155d0] {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background: #ccebb8;
  margin-right: 10px;
}
.dateClasst .screenIconB .screenIcon[data-v-5cf155d0] {
  width: 100%;
  height: 100%;
}
[data-v-5cf155d0] .el-input--suffix input {
  height: 32px !important;
}
.el-menu[data-v-5cf155d0] {
  margin-bottom: 6px;
  border-right: none;
}
.dateClass[data-v-5cf155d0] {
  background: #5cb85c;
  border-radius: 2px;
  color: #ffffff;
  width: 80px;
  text-align: center;
}
[data-v-5cf155d0] .el-table {
  font-size: 12px;
}
[data-v-5cf155d0] .el-table .cell {
  padding: 8px 10px !important;
}
[data-v-5cf155d0] .el-table__header .el-table__cell {
  background: #fcfcfc !important;
  border-right: none;
}
[data-v-5cf155d0] .el-menu-item {
  padding: 0 14px !important;
  height: 54px !important;
  line-height: 54px !important;
}