
[data-v-b5bacd56] .el-upload--picture-card {
  border: none !important;
  background: #ffffff;
  width: auto;
  height: auto;
  line-height: normal;
}

/*去除upload组件过渡效果*/
[data-v-b5bacd56] .el-upload-list__item {
  -webkit-transition: none !important;
  transition: none !important;
}
[data-v-b5bacd56] .el-upload-list__item-thumbnail {
  /* 图片在方框内显示长边 */
  -o-object-fit: scale-down !important;
     object-fit: scale-down !important;
}
[data-v-b5bacd56] .el-upload-list__item {
  display: none !important;
}
