.text-cursor[data-v-4f62ce51] {
  cursor: pointer;
}
[data-v-4f62ce51] .el-input__inner {
  background: none !important;
  color: #606266 !important;
}
[data-v-4f62ce51] .el-textarea__inner {
  background: none !important;
  color: #606266 !important;
}
.contentValitem[data-v-4f62ce51] {
  margin: 0 20px 0;
}
.contentValitemt[data-v-4f62ce51] {
  margin: 0;
}
.main_contentClass[data-v-4f62ce51] {
  width: 100%;
  margin-bottom: 10px;
}
.serverOption[data-v-4f62ce51] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.addtag[data-v-4f62ce51] {
  padding: 2px 4px;
  border: 1px solid #999999;
  color: cornflowerblue;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}
.first_box[data-v-4f62ce51] {
  margin-right: 10px;
  width: 90px;
}
.x-f[data-v-4f62ce51] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.popover[data-v-4f62ce51] {
  width: 860px;
}
.leftItem[data-v-4f62ce51] {
  margin-right: 10px;
}
[data-v-4f62ce51].el-date-editor.el-input {
  width: 100% !important;
}