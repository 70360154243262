.text-cursor[data-v-06e177a1] {
  cursor: pointer;
}
.fileeesss.add-header[data-v-06e177a1] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.fileeesss .child_box[data-v-06e177a1] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.fileeesss .child_box .first_box[data-v-06e177a1] {
  line-height: 41px;
  font-size: 16px;
  margin-right: 30px;
}
.fileeesss .avatar-uploader .el-upload[data-v-06e177a1] {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.fileeesss .avatar-uploader .el-upload[data-v-06e177a1]:hover {
  border-color: #409eff;
}
.fileeesss .avatar-uploader-icon[data-v-06e177a1] {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}
.fileeesss .avatar[data-v-06e177a1] {
  width: 178px;
  height: 178px;
  display: block;
}
.fileeesss .avatar-uploader[data-v-06e177a1] {
  margin-right: 170px;
}