.text-cursor[data-v-e8d7c1c0] {
  cursor: pointer;
}
.icon-group[data-v-e8d7c1c0] {
  text-align: center;
  font-size: 200px;
}
.icon-tag[data-v-e8d7c1c0] {
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 10px;
}
.iconStyle[data-v-e8d7c1c0] {
  font-size: 30px;
}