.text-cursor[data-v-754147d5] {
  cursor: pointer;
}
.dialog-footer[data-v-754147d5] {
  margin-left: 120px;
}
[data-v-754147d5] .el-textarea__inner {
  height: 200px !important;
}
.start_day_item[data-v-754147d5] {
  margin-right: 10px;
}
.start_day_text[data-v-754147d5] {
  margin-right: 10px;
}