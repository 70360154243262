.text-cursor[data-v-8f52a764] {
  cursor: pointer;
}
.el-table .cell[data-v-8f52a764] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content-navtow[data-v-8f52a764] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}
.content-navtow .content-titletow[data-v-8f52a764] {
  color: #15C5CE;
  font-size: 15px;
  font-weight: bold;
  position: relative;
  padding: 10px 0;
}
.content-navtow .content-titletow .BotSlide[data-v-8f52a764] {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #15C5CE;
  width: 40%;
  height: 4px;
  border-radius: 4px;
}
[data-v-8f52a764] ::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
[data-v-8f52a764] ::-webkit-scrollbar-track-piece {
  background: none !important;
}
[data-v-8f52a764] ::-webkit-scrollbar-thumb {
  background: #E4E7E8;
}
[data-v-8f52a764] .el-table__cell {
  background: none !important;
  padding: 7px 0 !important;
  font-size: 12px;
}
[data-v-8f52a764] .el-table__body-wrapper {
  height: 260px !important;
}
[data-v-8f52a764] thead .el-table__cell {
  color: #000000 !important;
}
[data-v-8f52a764] .el-table {
  color: #696969 !important;
}
.tables[data-v-8f52a764] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.tables .tables-item[data-v-8f52a764] {
  width: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 20px 10px;
}
.tables .tables-item .tables-data[data-v-8f52a764] {
  padding: 20px 0;
}
.content[data-v-8f52a764] {
  padding-top: 50px;
}
.content .content-nav[data-v-8f52a764] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.content .content-title[data-v-8f52a764] {
  font-size: 18px;
  font-weight: bold;
}
.content .realTimeData[data-v-8f52a764] {
  background: #FAFAFA;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.content .realTimeData .realTimeData-title[data-v-8f52a764] {
  font-size: 14px;
  font-weight: 500;
}
.content .realTimeData .realTimeData-content[data-v-8f52a764] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.content .realTimeData .realTimeData-content .realTimeData-item[data-v-8f52a764] {
  width: 200px;
  padding: 20px 0 0;
}
.content .realTimeData .realTimeData-content .realTimeData-item .realTimeData-num[data-v-8f52a764] {
  font-size: 20px;
  font-weight: bold;
}
.realTimeData-tle[data-v-8f52a764] {
  font-size: 10px;
  font-weight: 500;
}
.realTimeData-tle .el-icon-warning-outline[data-v-8f52a764] {
  margin-left: 6px;
}
[data-v-8f52a764].el-date-editor--daterange {
  width: 280px !important;
}
.navigation[data-v-8f52a764] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 10px 20px;
  -webkit-box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.09);
          box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.09);
}
.navigation .navigation-title[data-v-8f52a764] {
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.navigation .navigation-name[data-v-8f52a764] {
  padding: 4px 8px;
  background: #FAFAFA;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  margin-left: 20px;
}
.navigation .navigation-dro[data-v-8f52a764] {
  margin-left: 20px;
  font-size: 8px;
  background: #F5F5F5;
  padding: 2px;
}