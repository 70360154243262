.text-cursor[data-v-35063f8c] {
  cursor: pointer;
}
.lodingItem-title[data-v-35063f8c] {
  min-width: 70px !important;
}
[data-v-35063f8c] .el-input__inner {
  background: none !important;
  color: #606266 !important;
}
[data-v-35063f8c] .el-textarea__inner {
  background: none !important;
  color: #606266 !important;
}