.text-cursor {
  cursor: pointer;
}
.reel .bootDevice-body {
  border: 1px solid #dfe6ec;
  margin-top: 50px;
  width: 100%;
  overflow: auto;
  color: #909399;
  max-height: 700px;
}
.reel .block {
  margin: 20px 0;
  text-align: center;
}
.top_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 0;
  background-color: #fff;
}