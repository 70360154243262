.text-cursor {
  cursor: pointer;
}
.latestToday {
  padding: 10px 0;
}
.latestTodayItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 80px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.latestTodayItem .latestToday-img {
  width: 120px;
  height: 120px;
}
.latestTodayItem .latestToday-right {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 200px;
}
.latestTodayItem .latestToday-right .latestToday-t {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-bottom: 10px;
}
.latestTodayItem .latestToday-right .latestToday-t .latestToday-title {
  font-size: 20px;
  color: #959595;
  width: 120px;
}
.latestTodayItem .latestToday-right .latestToday-t .latestToday-desc {
  color: #dd9798;
  margin-left: 10px;
}
.latestTodayItem .latestToday-right .latestToday-num {
  font-size: 26px;
  color: #666666;
}
.latestTodayItem .latestToday-right .latestToday-b {
  font-size: 14px;
  color: #ccb2a4;
}