.text-cursor {
  cursor: pointer;
}
* {
  margin: 0;
  padding: 0;
}
.tesing.dataAll-content .table {
  border-collapse: collapse;
  color: #909399;
}
.tesing.dataAll-content th {
  background: #eee;
}
.tesing.dataAll-content th,
.tesing.dataAll-content td {
  border: 1px solid #dfe6ec;
  text-align: center;
  min-width: 120px;
  font-weight: normal;
  background: #fff;
}
.tesing.dataAll-content td {
  width: 100px;
  line-height: 40px;
}
.tesing.dataAll-content .test_data-body {
  border-left: 1px solid #dfe6ec;
  height: 640px;
  clear: both;
  margin-top: 50px;
}
.tesing.dataAll-content .data-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.tesing.dataAll-content .el-picker-panel__body td {
  border: 0;
}
.tesing.dataAll-content .el-picker-panel__body th {
  border: 0;
}