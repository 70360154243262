.text-cursor {
  cursor: pointer;
}

.el-table[is-sticky] {
  overflow: initial;
  --sticky-top: 0px;
  --stick-zIndex: 5;
}
.el-table[is-sticky] .el-table__header-wrapper {
  position: sticky;
  top: var(--sticky-top);
  z-index: var(--stick-zIndex);
}
.el-table[is-sticky] .el-table__fixed, .el-table[is-sticky] .el-table__fixed-right {
  overflow: visible;
  z-index: var(--stick-zIndex);
}
.el-table[is-sticky] .el-table__fixed .el-table__fixed-header-wrapper, .el-table[is-sticky] .el-table__fixed-right .el-table__fixed-header-wrapper {
  position: sticky;
  top: var(--sticky-top);
  width: 100%;
  overflow: hidden;
  z-index: var(--stick-zIndex);
}
.el-table[is-sticky] .el-table__fixed .el-table__fixed-body-wrapper, .el-table[is-sticky] .el-table__fixed-right .el-table__fixed-body-wrapper {
  width: 100%;
  overflow: hidden;
}
.el-table[is-sticky] .el-table__fixed-right .el-table__fixed-header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.el-table[is-sticky] .el-table__fixed-right .el-table__fixed-body-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.el-table[is-sticky].el-table--border::after {
  z-index: var(--stick-zIndex);
}

.el-table__fixed {
  --box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.12);
}

.el-table__fixed-right {
  --box-shadow: -10px 0 10px -10px rgba(0, 0, 0, 0.12);
}

.el-table__fixed, .el-table__fixed-right {
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}