.text-cursor {
  cursor: pointer;
}
.gameDtailllsss.dataAll-content {
  width: 100%;
}
.gameDtailllsss.dataAll-content .dataCompare-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.gameDtailllsss.dataAll-content .dataCompare-header .el-date-picker {
  width: 200px;
}
.gameDtailllsss.dataAll-content .box-posie {
  position: relative;
}
.gameDtailllsss.dataAll-content .levelDetails-body {
  margin-top: 30px;
  border: none;
}
.gameDtailllsss.dataAll-content .block {
  margin: 20px 0;
  text-align: center;
}
.gameDtailllsss.dataAll-content .top_btn {
  margin: 10px 0 0px;
  border: 0;
  background-color: #fff;
}
.gameDtailllsss .button_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}