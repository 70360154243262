
.block[data-v-b36a029a] {
  margin: 20px 0;
  text-align: center;
}
.top_btn[data-v-b36a029a] {
  margin: 10px 0 20px;
  border: 0;
  background-color: #fff;
}
.statusTag1[data-v-b36a029a] {
  color: red;
}
.statusTag2[data-v-b36a029a] {
  color: green;
}
