
.popover[data-v-bf9aed2e] {
  width: 900px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.popover-item[data-v-bf9aed2e] {
  width: 24%;
  border: 1px solid#666666;
  /* border-radius: 10px; */
}
.gameItem[data-v-bf9aed2e]{
  margin: 0 20px 30px 0;
}
.gameItem-icon[data-v-bf9aed2e] {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.gameItem-text[data-v-bf9aed2e] {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  overflow:hidden;
  width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
